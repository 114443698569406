import "./App.css";
import "./assect/flexible";

function App() {
  return (
    <div className="main">
      <div>
        {/* 背景 */}
        <div className="bg">
          {/* phone */}
          <img src='https://qy-rc.vivadyw.com/vivadyw/phone.png' alt='phone' className='phone'></img>
        </div>

        {/* 内容区域 */}
        <div className="content">
          {/* 图标 */}
          <img src='https://qy-rc.vivadyw.com/vivadyw/logo.png' alt="logo" className="icon"></img>

          <h2 className="title-h2">Vakie <br />
            Music Video Maker</h2>
          {/* 下载方式 */}
          <div>
            <a
              href="https://apps.apple.com/us/app/vakie-music-video-maker/id1476019855"
              className="appleTarget"
            >
              <img src='https://qy-rc.vivadyw.com/vivadyw/button_applestore.png' alt="apple" className="appleImg"></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.mini.vakie&hl=en&gl=US"
              className="google"
            >
              <img src='https://qy-rc.vivadyw.com/vivadyw/button_gp.png' alt="googleImg" className="googleImg"></img>
            </a>
          </div>
        </div>
      </div>

      <div className="imgs">
        <img src='https://qy-rc.vivadyw.com/vivadyw/mo.png' alt="slice1"></img>
        <img src='https://qy-rc.vivadyw.com/vivadyw/ka.png' alt="slice2"></img>
        <img src='https://qy-rc.vivadyw.com/vivadyw/al_face.png' alt="slice3"></img>
      </div>

      {/* 尾部 */}
      <div>
        <div className="footer">
          <a href="https://qy-rc.vdresource.com/argeement/vakie-user-agreement-ios.html">Terms of use</a>
          <a href="https://qy-rc.vdresource.com/argeement/vakie-privacy-policy-ios.html">Privacy Policy</a>
          <span className="contact">
            <div>Contact us</div>
            <div className="pop">
              If you have some problem when using Vakie, you can tell us in Instagram or Email us: <br></br>
              <span>vivamini123@gmail.com</span>
            </div>
          </span>
        </div>
        {/* 备案信息 */}
        <div className="remark">
          Copyright © 2020 杭州影趣科技有限公司 版权所有 <a href="https://beian.miit.gov.cn/">浙ICP备2021012310号-1 </a>
        </div>
      </div>



    </div>
  );
}

export default App;
